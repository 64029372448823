* {
	font-family: IRANsans;
	direction: rtl;
}
.sidebar {
	z-index: 0 !important;
}
.cur-pointer {
	cursor: pointer;
}
html[dir="rtl"] .profile-nav-area {
	margin-left: inherit !important;
	margin-right: auto !important;
}

aside.aside-menu {
	.list-group {
		padding: 0;
	}
}

caption {
	caption-side: top;
}
.roundImg {
	border-radius: 50%;
	width: 55px;
	text-align: center;
}
.flex-direction-row {
	display: flex;
	flex-direction: row;
}

.borderRadius {
	border-radius: 15px;
}
.red {
	color: darkred;
}
.directionRow {
	flex-direction: row;
}
.spaceAround {
	justify-content: space-between;
}
.text-blue {
	color: dodgerblue;
}
.d-rel {
	position: relative;
}

.myLoading {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	z-index: 99;
}

.table-row-center {
	tr {
		td {
			vertical-align: middle;
		}
	}
}

.JDBackground {
	z-index: 1;
}

.JDsubmit,
.JDcancel {
	white-space: nowrap;
	width: auto !important;
	padding: 4px 8px;
	height: auto !important;
}

.trashNamedList:hover {
	color: red;
}

.filter {
	background-color: #2f353a;
	.colorWhite {
		color: white;
	}
}

.f-18 {
	font-size: 18px;
	font-weight: 500 !important ;
}

.green {
	color: "green";
}
